import { mapState, mapActions } from 'vuex';
import util from '../../../share/util/utils';
import cstmTable from '../../../share/components/vue/PaginationTable';

export default {
  name: 'detailFdModal',
  data() {
    return {
      statusLabel: '',
      statusValue: '',
      detailFdTableColumns: [],
      detailFdTableItems: [],
    };
  },

  props: {
    detailFdTableProp: { type: Array, default: [], required: false },
    selectedStatus: { type: String, default: "", required: false },
    modalLoading: { type: Boolean, required: true },
  },

  components: {
    'cstm-table-modal': cstmTable,
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('cache', ['cacheProjectDetails']),

    /**
     * 詳細FD 一覧の生成
     */
    updateDetailFdTable() {
      // リソース(作動履歴_DID)のi18nキー取得
      const i18nWsDidKey = `did`;
      this.statusLabel = this.$t(`message.header_vehicle_dtc_table_status`) + "：";
      this.statusValue = this.selectedStatus;

      // 項目・単位は固定で設定
      this.detailFdTableColumns = [
        { name: 'item', label: 'message.header_vehice_ffd_item' },
        { name: 'unit', label: 'message.header_vehice_ffd_unit' }
      ];
      const header_cnt = this.$t(`individual.header_vehicle_system_table_cnt`);
      const fdDetailList = [];
      for (const obj of this.detailFdTableProp) {
        const fdDetailObject = {};
        for (const key in obj) {
          if (key === 'did') {
            fdDetailObject.item = this.$t(`${i18nWsDidKey}.` + obj[key]);
            // 画面に表示しないが、初期ソート用にDID値をセット
            fdDetailObject.did = obj[key].split('_')[0];
          } else if (key === 'unit_id') {
            fdDetailObject.unit = this.$t(`didUnit.` + obj[key]);
          } else if (key === 'read_data') {
            // no でソート
            obj[key].sort((a, b) => a.no > b.no ? 1 : -1);
            const allHyphenFlg = obj[key].every((data) => data.value === 0);
            // read_data をloopし、文言ファイルに一致するキーが存在する場合は対象の文言を、data_typeがvalueは丸め処理を、それ以外は値をそのままセット
            for (const fdData of obj[key]) {
              const messageKey = `${i18nWsDidKey}.${obj.did}_${fdData.value}`;
              const keyName = "count_" + fdData.no;
              fdDetailObject[keyName] = this.$te(messageKey) ? this.$t(messageKey) :
                allHyphenFlg ? this.$t(`message.common_hyphen`) :
                  obj.data_type === 'Value' ? Number(fdData.value.toFixed(obj.digits_after_decimal)) : fdData.value;
            }
          }
        }
        fdDetailList.push(fdDetailObject);
      }
      for (const element of this.detailFdTableProp[0].read_data) {
        this.detailFdTableColumns.push({
          "name": "count_" + element.no,
          "label": util.setColums(element.no, header_cnt, "{0}{1}"),
          "useHtmlBind": true,
        });
      }
      this.detailFdTableItems = fdDetailList;
    },

    async csvExport() {
      this.$emit('update-loading', true);

      // テストdataを生成する
      let header = "";
      let body = "";

      const nameData = this.detailFdTableColumns.map(item => item["name"]);
      const headerLabelList = this.detailFdTableColumns.map(key => key);
      // VIN/車台番号
      const vinLabel = this.$t('message.label_measured_data_search_vin');
      const vinData = util.escapeForCsv(this.cachedDetails.information.vin);
      const vinHeader = `"${vinLabel}",${vinData}\n`;
      const statusLabel = this.$t(`message.header_vehicle_dtc_table_status`);
      const statusValue = util.escapeForCsv(this.selectedStatus);
      const statusHeader = `"${statusLabel}",${statusValue}\n`;

      header = headerLabelList.map(head => {
        if (head["name"].includes("count_")) {
          return head["label"];
        }
        return this.$t(head["label"]) ? this.$t(head["label"]) : head;
      }).join(",") + "\n";
      header = vinHeader + statusHeader + header;
      body = this.detailFdTableItems.map((d) => {
        return nameData.map((key) => {
          return util.escapeForCsv(d[key].toString());
        }).join(",");
      }).join("\n");
      header = header.replaceAll("\n,", "\n");

      // ダミー処理（ファイルエクスポートが高速で終了するので、操作者に通信の発生を認識させる）
      await util.sleep(1000);
      this.$emit('update-loading', false);

      const data = header + body;
      const bomFormatData = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
      const blob = new Blob([bomFormatData, data], { type: 'text/csv;charset=utf-8' });
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = ('{0}.csv').format("FD"); // 出力するファイルの名前
      link.style.display = 'none';
      link.click();
      link.remove();
    },
  },

  computed: {
    ...mapState({
      cachedDetails: state => state.cache.projectDetails
    }),
  },

  /**
   * createdイベント
   */
  created() {
    // noop
  },

  /**
   * mountedイベント
   */
  mounted() {
    this.updateDetailFdTable();
  },
};