<div class="container-fluid ml-n2">

  <div>
    <div>
      <b-button class="ml-2 mt-2" variant="primary" size="sm" v-on:click="openNoteModal" :disabled="!projectId">
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
    </div>
  </div>

  <!-- 変換済項目を画面に表示 -->
  <div class="text-left align-middle">
    <div v-for="(item, index) in showData" :key="index">
      <div>
        <b>{{ item.vehicle_info }}</b>
        <div v-for="(bc, inx) in item.body" :key="inx">
          <b>{{ bc.item_name + " " }}</b>
          <b>{{ bc.value + " " }}</b>
          <b>{{ bc.unit === "-" ? "" : bc.unit }}</b>
        </div>
      </div>
        <br />
    </div>
  </div>

  <!-- 車両情報取得実行中 -->
  <b-modal id="modal-before-get-vehicle-information" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
  no-close-on-backdrop no-close-on-esc centered hide-footer>
  <template v-slot:modal-header>
    <div class="text-center w-100">
      {{ $t('message.header_label_execution') }}
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="modalLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid mt-2 mb-2" style="min-height: 140px;"></div>
    </b-overlay>     
  </template>
  </b-modal>

  <!-- メモ画面 -->
  <b-modal id="modal-memo" v-if="isMemoOpen" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:modal-header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-Page ref="memo" :projectId="projectId"
      :measureId="measureId"
      functionNameKey="message.label_function_vehicle_connect"
      @closeNoteModal="closeNoteModal"
      :modalLoading="isMemoDialogLoading" @update-loading="isMemoDialogLoading = $event" />

    <template v-slot:footer="{ close, ok }">
      <button type="button" :disabled="isMemoDialogLoading" v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill">{{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="outline-primary" @click="closeNoteModal" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="primary" @click="memoSave()" class="ml-2 mr-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 実行不可画面 -->
  <b-modal v-if="showErrorVehicleModal" v-model="showErrorVehicleModal" id="modal-error-get-vehicle-information" size="md" header-bg-variant="danger" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking>
    <template v-slot:header>
      <div class="text-center w-100">
          {{ $t('message.common_title_error') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid" style="min-height: 140px;">
        <div class="w-100">
          <label class="box-margin-right modal-error-message">{{ 
            $t('fixed_resource.obd_function_common_message_result_ng_2') }}</label>
        </div>
      </div>
    </template>
    
    <template v-slot:footer="{ ok }">
      <div class="w-100 text-center ">
        <b-button size="sm" variant="primary" @click="closeWizard()" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>

</div>