import * as uiConfigs from "../constants/uiConfigs";
import {
  FUNCTION_KEY_ONBOARD_SYSTEM_TEST,
  FUNCTION_KEY_VEHICLE_INFO,
  FUNCTION_KEY_DTC,
  FUNCTION_KEY_P_DTC,
  FUNCTION_KEY_ONBOARD_MONITOR_TEST,
} from "../util/obd/functions";

import config from "@/share/config";
import logWrapper from "@/share/logWrapper";
import utils from "@/share/util/utils";
import { pushAction } from "@/share/util/router";

import Obd from '@/store/modules/obd';

import componentTop from "@/components/vue/obd/ObdSystemTop";
import componentSystemList from "@/components/vue/obd/ObdSystemList";
import componentIndividualMenu from "@/components/vue/obd/ObdIndividualMenu";
import componentOnboardSystemTest from "@/components/vue/obd/individual/ObdOnboardSystemTest";
import componentGetVehicleInfo from "@/components/vue/obd/individual/ObdGetVehicleInfo";
import componentOnboardMonitorTest from "@/components/vue/obd/individual/ObdOnboardMonitorTest";
import componentDtc from "@/components/vue/obd/individual/ObdDtc";
import componentPermanentDtc from "@/components/vue/obd/individual/ObdPermanentDtc";
import componentAllDtc from "@/components/vue/obd/ObdAllDtc";

/** Start 画面側の OBD システムであることを指す */
export const PAGE_FROM_START = "start";
/** General 画面側の OBD システムであることを指す */
export const PAGE_FROM_GENERAL = "general";

/** OBD システムのトップページを指す */
export const PAGE_KEY_TOP = "top";
/** OBD全DTC画面を指す */
export const PAGE_KEY_ALL_DTC = "allDtc";
/** OBDシステム一覧画面を指す */
export const PAGE_KEY_SYSTEM_LIST = "systemList";
/** OBD個別診断一覧画面を指す */
export const PAGE_KEY_INDIVIDUAL_MENU = "individualMenu";
/** OBD テスト選択画面を指す */
export const PAGE_KEY_ONBOARD_SYSTEM_TEST = "onboardSystemTest";
/** OBD 車両情報取得画面を指す */
export const PAGE_KEY_GET_VEHICLE_INFO = "obdGetVehicleInfo";
/** OBD オンボードモニタテスト画面 */
export const PAGE_KEY_ONBOARD_MONITOR_TEST = "obdOnboardMonitorTest";
/** OBD ダイアグコード画面を指す */
export const PAGE_KEY_INDIVIDUAL_DTC = "individualDtc";
/** OBD パーマネントダイアグコード画面を指す */
export const PAGE_KEY_INDIVIDUAL_PERMANENT_DTC = "individualPermanentDtc";

/**
 * ページ情報をまとめたオブジェクト
 */
const PAGE_INFO = {
  [PAGE_KEY_TOP]: {
    breadCrumbLevel: 3,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_COMMON_MAIN,
    component: componentTop,
    [PAGE_FROM_START]: {
      path: "/obd/top",
      name: "obdSystemTop",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/top",
      name: "generalObdSystemTop",
    },
  },
  [PAGE_KEY_SYSTEM_LIST]: {
    breadCrumbLevel: 4,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentSystemList,
    [PAGE_FROM_START]: {
      path: "/obd/systems",
      name: "obdSystemList",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems",
      name: "generalObdSystemList",
    },
  },
  [PAGE_KEY_INDIVIDUAL_MENU]: {
    breadCrumbLevel: 5,
    breadCrumbData: (to, from) => {
      from;
      const systemNo = to?.params?.systemNo;
      const systemName = (Obd?.state?.systems || []).find(system => system.systemNo === systemNo)?.systemName;
      return utils.createBreadCrumbBaseData(to, undefined, systemName);
    },
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentIndividualMenu,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/individualMenu",
      name: "obdIndividualMenu",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/individualMenu",
      name: "generalObdIndividualMenu",
    },
    props: true
  },
  [PAGE_KEY_ONBOARD_SYSTEM_TEST]: {
    breadCrumbLevel: 6,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentOnboardSystemTest,
    funcKey: FUNCTION_KEY_ONBOARD_SYSTEM_TEST,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/onboardSystemTest",
      name: "obdOnboardSystemTest",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/onboardSystemTest",
      name: "generalObdOnboardSystemTest",
    },
    props: true
  },
  [PAGE_KEY_GET_VEHICLE_INFO]: {
    breadCrumbLevel: 6,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentGetVehicleInfo,
    funcKey: FUNCTION_KEY_VEHICLE_INFO,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/individualMenu/getVehicleInfo",
      name: "obdGetVehicleInfo",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/individualMenu/getVehicleInfo",
      name: "generalObdGetVehicleInfo",
    },
    props: true
  },
  [PAGE_KEY_ONBOARD_MONITOR_TEST]: {
    breadCrumbLevel: 6,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentOnboardMonitorTest,
    funcKey: FUNCTION_KEY_ONBOARD_MONITOR_TEST,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/onboardMonitorTest",
      name: "obdOnboardMonitorTest",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/onboardMonitorTest",
      name: "generalObdOnboardMonitorTest",
    },
    props: true
  },
  [PAGE_KEY_INDIVIDUAL_DTC]: {
    breadCrumbLevel: 6,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentDtc,
    funcKey: FUNCTION_KEY_DTC,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/dtc",
      name: "obdDtc",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/dtc",
      name: "generalObdDtc",
    },
  },
  [PAGE_KEY_INDIVIDUAL_PERMANENT_DTC]: {
    breadCrumbLevel: 6,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentPermanentDtc,
    funcKey: FUNCTION_KEY_P_DTC,
    [PAGE_FROM_START]: {
      path: "/obd/systems/:systemNo/permanentDtc",
      name: "obdPermanentDtc",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/systems/:systemNo/permanentDtc",
      name: "generalObdPermanentDtc",
    },
  },
  [PAGE_KEY_ALL_DTC]: {
    breadCrumbLevel: 4,
    headerType: uiConfigs.headerType_start,
    containerStyle: uiConfigs.CONTAINER_STYLE_EXECUTE,
    component: componentAllDtc,
    [PAGE_FROM_START]: {
      path: "/obd/allDtc",
      name: "obdAllDtc",
    },
    [PAGE_FROM_GENERAL]: {
      path: "/menu/obd/allDtc",
      name: "generalObdAllDtc",
    },
    props: true
  },
};

/**
 * 指定したページ情報を取得する
 * @param {string} key ページのキー
 * @returns {object} ページ情報
 */
const getPageInfo = (key) => (PAGE_INFO || {})[key] || {};

/**
 * Router 情報の生成を行う。
 * @param {string} pageKey ページのキーを指定
 * @param {string} pageType {@link PAGE_FROM_START} か {@link PAGE_FROM_GENERAL} を指定
 * @returns {object} Router 情報
 */
export const createRouteInfo = (pageKey, pageType) => {
  const pageInfo = getPageInfo(pageKey);
  if (!pageInfo) {
    logWrapper.log(
      `[router/obd] createRouteInfo: getPageInfo failed. pageKey: ${pageKey}`
    );
  }

  const ret = {
    path: pageInfo[pageType].path,
    name: pageInfo[pageType].name,
    component: pageInfo.component,
    meta: {
      requiresAuth: true,
      breadCrumbLevel:
        pageType === PAGE_FROM_GENERAL
          ? pageInfo.breadCrumbLevel + 1 // General 画面は階層が1つずれるので、一律 +1 とする
          : pageInfo.breadCrumbLevel,
      breadCrumbData: pageInfo.breadCrumbData || null,
      headerType: pageInfo.headerType || uiConfigs.headerType_vehicle,
      flowNavigationType:
        pageInfo.flowNavigationType || config.NAVIGATION_DISPLAY,
      viewProcessMode: pageInfo.viewProcessMode || config.PROCESS_IDLING,
      containerStyle: pageInfo.containerStyle || "",
    },
    props: pageInfo.props || false
  };

  return ret;
};

/**
 * 現在のページが指定したキーと一致するか確認する
 * @param {string} name Vue の this.$route?.name を指定する
 * @param {string} key ページのキー
 * @returns {boolean} 現在のページが「OBDシステム（トップ）」であれば true、それ以外は false
 */
export const isMatchCurrentPage = (name, key) =>
  name === getPageInfo(key)[PAGE_FROM_START]?.name ||
  name === getPageInfo(key)[PAGE_FROM_GENERAL]?.name;

/**
 * 現在いるページ情報を取得する。
 * @param {string} name Vue の this.$route?.name を指定する
 * @returns {object} 一致したページ情報
 */
const getCurrentPage = (name) => {
  const pageInfo = Object.values(PAGE_INFO).find(
    (value) =>
      value[PAGE_FROM_START]?.name === name ||
      value[PAGE_FROM_GENERAL]?.name === name
  );
  if (!pageInfo) {
    logWrapper.log(
      `[router/obd] getCurrentPage: page info is not found. name: ${name}`
    );
  }

  return pageInfo;
};

/**
 * 現在いるページの種類 (Start or General) を取得する。
 * @param {string} name Vue の this.$route?.name を指定する
 * @returns {string} 一致した場合は {@link PAGE_FROM_START} か {@link PAGE_FROM_GENERAL} を返す。見つからない場合は空文字を返す。
 */
const getCurrentPageFrom = (name) => {
  const pageInfo = getCurrentPage(name);
  if (pageInfo && pageInfo[PAGE_FROM_START]?.name === name)
    return PAGE_FROM_START;
  if (pageInfo && pageInfo[PAGE_FROM_GENERAL]?.name === name)
    return PAGE_FROM_GENERAL;

  logWrapper.log(
    `[router/obd] getCurrentPageFrom: page info is not found. name: ${name}`
  );
  return "";
};

/**
 * 画面上部にアンカースクロールする。
 */
const scrollToTop = () => {
  // 画面上部へのアンカースクロール
  document
    ?.getElementById(uiConfigs.ID_SCROLL_TAB_BLOCK)
    ?.scrollIntoView({ behavior: "instant" });
};

/**
 * ページの名前を取得する。
 */
const getPageName = (component, pageKey, argPageType = null) => {
  const pageType = argPageType || getCurrentPageFrom(component?.$route?.name);
  const name = getPageInfo(pageKey)?.[pageType]?.name;

  if (!name) {
    logWrapper.log(
      `[router/obd] getPageName: page name is not found. routeName: ${component?.$route?.name}, pageKey: ${pageKey}, pageType: ${pageType}`
    );
  }

  return name;
};

/**
 * 「OBDシステム」ページ（トップページ）に移動する
 * @param {object} component Vue の this を指定する
 * @param {string} pageType {@link PAGE_FROM_START} か {@link PAGE_FROM_GENERAL} を指定
 * @param {string} obdMenu OBD のメニュー（リダイレクトさせる場合のみ使用）（"allDtc", "individual"）
 * @param {string} systemNo OBD のシステム (ECU) 番号（リダイレクトさせる場合のみ使用）
 * @param {string} funcKey 機能（リダイレクトさせる場合のみ使用）
 */
export const pushObdTop = (component, pageType, obdMenu, systemNo, funcKey) => {
  // 自画面への遷移の場合、何もしない
  if (isMatchCurrentPage(component?.$route?.name, PAGE_KEY_TOP)) {
    logWrapper.log(
      `[router/obd] pushObdTop: isMatchCurrentPage is true. routeName: ${component?.$route?.name}`
    );
    return;
  }

  const name = getPageName(component, PAGE_KEY_TOP, pageType);
  // リダイレクト用のクエリパラメータを設定
  const queryInfo = {
    obdMenu: obdMenu,
    systemNo: systemNo,
    funcKey: funcKey,
  };
  pushAction(component, name, queryInfo);

  // 画面上部へのアンカースクロール
  scrollToTop();
};

/**
 * 「OBD全DTC画面」ページに移動する
 * @param {object} component Vue の this を指定する
 */
export const pushObdAllDtc = (component) => {
  const name = getPageName(component, PAGE_KEY_ALL_DTC);
  pushAction(component, name);

  // 画面上部へのアンカースクロール
  scrollToTop();
};

/**
 * 「OBDシステム一覧画面」ページに移動する
 * @param {object} component Vue の this を指定する
 * @param {string} systemNo OBD のシステム (ECU) 番号（リダイレクトさせる場合のみ使用）
 * @param {string} funcKey 機能（リダイレクトさせる場合のみ使用）
 */
export const pushObdSystemList = (component, systemNo, funcKey) => {
  // 画面遷移
  const name = getPageName(component, PAGE_KEY_SYSTEM_LIST);
  pushAction(component, name, {
    systemNo: systemNo,
    funcKey: funcKey,
  });

  // 画面上部へのアンカースクロール
  scrollToTop();
};

/**
 * 「機能選択」ページに移動する
 * @param {object} component Vue の this を指定する
 * @param {string} systemNo OBD のシステム (ECU) 番号
 * @param {string} funcKey 機能（リダイレクトさせる場合のみ使用）
 */
export const pushFunctionList = (component, systemNo, funcKey) => {
  // 画面遷移
  const name = getPageName(component, PAGE_KEY_INDIVIDUAL_MENU);
  pushAction(component, name, {
    systemNo: systemNo,
    funcKey: funcKey,
  }, {
    systemNo: systemNo,
  });

  // 画面上部へのアンカースクロール
  scrollToTop();
};

/**
 * 「各個別診断の機能」ページに移動する
 * @param {object} component Vue の this を指定する
 * @param {string} systemNo OBD のシステム (ECU) 番号
 * @param {string} funcKey 機能のキー
 */
export const pushFunction = (component, systemNo, funcKey) => {
  // ページキーを取得
  const pageKey = getPageKeyFromFuncKey(funcKey);
  // 画面遷移
  const name = getPageName(component, pageKey);
  pushAction(component, name, undefined, {
    systemNo: systemNo,
  });

  // 画面上部へのアンカースクロール
  scrollToTop();
};

/**
 * ヒットしたページのfuncKeyを返す
 * @param {string} name Vue の this.$route.name を指定する
 * @returns {string} ヒットしたページのfuncKey: 個別診断の機能を識別するキー
 */
export const getFuncKeyFromName = (name) => {
  // 一致している要素を探し、見つかったらfuncKeyを返す
  const funcKey = getCurrentPage(name)?.funcKey;
  if (!funcKey) {
    logWrapper.log(
      `[router/obd] getFuncKeyFromName: funcKey is not found. name: ${name}`
    );
  }

  return funcKey;
};

/**
 * ページのキーを取得する。
 * @param {string} funcKey funcKey を指定する
 * @returns {string} 一致した場合は {@link PAGE_FROM_START} か {@link PAGE_FROM_GENERAL} を返す。見つからない場合は空文字を返す。
 */
export const getPageKeyFromFuncKey = (funcKey) => {
  // 一致している要素を探し、見つかったらpageKeyを返す
  const pageInfo = Object.keys(PAGE_INFO).find(
    (key) => PAGE_INFO[key].funcKey === funcKey
  );
  if (!pageInfo) {
    logWrapper.log(
      `[router/obd] getPageInfoFromFuncKey: page info is not found. name: ${funcKey}`
    );
  }

  return pageInfo;
};
