<div class="container-fluid p-0" style="height: 100%">
  <div class="row no-gutters bg-white">
    <div :class="[{ 'col-3': isTreeViewOpen }, { 'width-40': !isTreeViewOpen }]">
      <!-- v-if 表示制御の場合、表示切替のたびに DOM 生成のイベントが走るため v-show を使用すること -->
      <div v-show="isTreeViewOpen" class="tree-seperator float-left">
        <div class="tree-header-div">
          <span class="tree-header-label float-left">{{ $t('message.header_vehicle_system_tree_diagnose') }}</span>
        </div>
        <div>
          <data-tree :tree-data="treeData" :showHeadElements="false" isAutoHeight
            @node-Selected="selectMeasuredData" />
        </div>
      </div>
      <div>
        <div class="div-arrow-radius" @click="switchTreeDisplay()">
          <svg v-if="isTreeViewOpen" viewBox="0 0 24 24" class="arrow-icon">
            <path d="M14 7l-5 5 5 5V7z" class="icon-color" />
            <path d="M24 0v24H0V0h24z" fill="none" />
          </svg>
          <svg v-else viewBox="0 0 24 24" class="arrow-icon">
            <path d="M10 17l5-5-5-5v10z" class="icon-color" />
            <path d="M0 24V0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
    </div>
    <div :class="[{ 'col-9': isTreeViewOpen }, { 'col': !isTreeViewOpen }]">
      <div class="row" style="width: 100%;">
        <div class="col-12 text-right mt-3">
          <svg-icon iconKey="carIcon"></svg-icon>
          <label v-if="isDomestic" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cn') }}
            {{ cachedDetails.information.vin }}</label>
          <label v-else class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_vin') }}
            {{ cachedDetails.information.vin }}</label>
          <label v-if="isDomestic" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cl') }}
            {{ mkHeaderValue(cachedDetails.information.classification) }}</label>
          <label v-else class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_my') }}
            {{ mkHeaderValue(cachedDetails.information.model_year) }}</label>
          <label class="vehicle-info-style" :class="{ 'mr-2': isDomestic }">
            {{ $t('message.label_vehicle_system_model') }} {{ mkHeaderValue(cachedDetails.information.vehicle_name) }}
          </label>
          <label v-if="isDomestic" class="vehicle-info-style">
            {{ $t('message.label_vehicle_system_applied_model') }} {{ mkHeaderValue(cachedDetails.information.applied_model) }}
          </label>
        </div>
        <div :class="isActive.dataMonitor || isActive.activeTest ? 'col-12' : 'col-12 mb-2'">
          <b-tabs>
            <b-tab :title="$t(navItemLabel)" active></b-tab>
          </b-tabs>
        </div>
        <div v-if="!(isActive.allDtc || isActive.workSupport)">
          <div :class="isActive.dataMonitor || isActive.activeTest ? 'col-12 mt-1' : 'col-12 mt-2'">
            <label class="sub-title-style">{{ $t(displayEcuName) }}</label>
          </div>
          <div class="col-12 mt-3" v-show="!(isActive.dataMonitor || isActive.activeTest)"></div>
        </div>
        <div v-else-if="isActive.workSupport">
          <div class="col-12 mt-2">
            <label class="sub-title-style">{{ $t(displayItemName) }}</label>
          </div>
          <div class="col-12 mt-3"></div>
        </div>
          <allDtc-paging v-show="isActive.allDtc" ref="all_dtc" :testModeVisible="testModeVisible" @csvExport="csvExport"
            @openMemoModal="openMemoModal" @openFFDModal="openFFDModal" @dtcTestDataExport="dtcTestDataExport" />
          <dtc-paging v-show="isActive.diagCode" ref="diag_code" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @openFFDModal="openFFDModal" @dtcTestDataExport="dtcTestDataExport"/>
          <readinessCode-paging v-show="isActive.readiness" ref="readiness_code" @csvExport="csvExport"/>
          <vehicleControlHistory-paging v-show="isActive.controlHistory" ref="vehicle_control_history" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @openBMDModal="openBMDModal" @bmcTestDataExport="bmcTestDataExport"/>
          <customize-paging v-show="isActive.customize" ref="customize" @openMemoModal="openMemoModal"/>
          <cancelCode-paging v-show="isActive.cancelCode" ref="cancel_code" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @didTestDataExport="didTestDataExport"/>
          <workSupport-paging v-show="isActive.workSupport" ref="work_support" @csvExport="csvExport"
            @openMemoModal="openMemoModal" @openDetailFDModal="openDetailFDModal" />
          <dataMonitor-paging v-show="isActive.dataMonitor || isActive.activeTest" ref="data_monitor" :isActiveTest="isActive.activeTest"
            @csvExport="csvExport" @openMemoModal="openMemoModal" @backToDetails="backToDetails" />
        <div class="col-12 text-right mb-2" v-show="!isActive.dataMonitor && !isActive.activeTest">
          <b-button variant="primary" size="sm" v-on:click="backToDetails">
            {{ $t('message.common_button_back') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>

  <!-- プロジェクトファイル変更ダイアログ -->
  <b-modal v-if="modalMemo" v-model="modalMemo" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-modal ref="project_memo" :projectId="projectId" :measureId="measuredId"
    @closeNoteModal="closeNoteModal" :functionNameKey="navItemLabel" :destinationAPI="destinationAPI"
    :modalLoading="isMemoDialogLoading" @update-loading="isMemoDialogLoading = $event" />

    <template v-slot:footer>
      <button type="button" :disabled="isMemoDialogLoading" v-on:click="clearMemo()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill">{{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="outline-primary" @click="modalMemo=false" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="primary" @click="saveMemo()" class="ml-2 mr-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- FFD 一覧画面 -->
  <b-modal v-if="modalFfd" v-model="modalFfd" size="huge" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- FFDタイトルの設定 -->
    <template v-slot:header>
      <div class="text-center w-100">{{ ffdTitle }}</div>
    </template>

    <ffd-modal :ffdTableProp="ffdTable" :systemProp="selectedSystem" :ffdSystem="ffdSystem" :ffdDescription="ffdDescription"
      :dataPlayback="dataPlayback" ref="ffd_modal">
      <template v-slot:didTestBtn>
        <b-button v-if="testModeVisible" class="clear-button" variant="primary" size="sm" @click="didTestDataExport()">
          Export DID Data
        </b-button>
      </template>
    </ffd-modal>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalFfd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- BMD 一覧画面 -->
  <b-modal v-if="modalBmd" v-model="modalBmd" size="huge" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>

    <template v-slot:header>
      <div class="text-center w-100">{{ bmdTitle }}</div>
    </template>

    <bmd-modal :bmdTableProp="bmdTable" :systemProp="selectedSystem" :bmdSystem="bmdSystem"
      :bmdDescription="bmdDescription" :dataPlayback="dataPlayback" ref="bmd_modal" >
      <template v-slot:didTestBtn>
        <b-button v-if="testModeVisible" class="clear-button" variant="primary" size="sm" @click="didTestDataExport()">
          Export DID Data
        </b-button>
      </template>
    </bmd-modal>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalBmd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 詳細FD画面 -->
  <b-modal v-if="modalDetailFd" v-model="modalDetailFd" size="huge" header-bg-variant="secondary"
    header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- 詳細FD画面タイトルの設定 -->
    <template v-slot:header>
      <div class="text-center w-100">{{ fdTitle }}</div>
    </template>

    <detail-fd-modal
      :detailFdTableProp="detailFdTable"
      :selectedStatus="status"
      :modalLoading="isDetailFdModalLoading"
      @update-loading="isDetailFdModalLoading = $event"
      ref="detail_fd_modal" />

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="isDetailFdModalLoading" @click="modalDetailFd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>