import individualDiagnose from "../../../share/util/individualDiagnose";

const TSV_CONTENT_TYPE = 'text/tab-separated-values;charset=utf-8;';

// 値の翻訳を実施するデータタイプ
export const VALUE_TRANSLATE_TYPE = ['FLG', 'HEX', 'decExStr'];
// 値の10進数変換を実施するデータタイプ
export const VALUE_CONVERT_DECIMAL_TYPE = [
  'Bin', 'min2hhmm', 'sec2hhmm', 'sec2hhmmss',
  'Lat_DEG', 'Lng_DEG', 'Lat_DMM', 'Lng_DMM', 'LatLng_DMM'
];
// Worker種別
export const WORKER = Object.freeze({
  REALTIME: 'realtime',
  RESTFUL: 'restful',
  MEASUREMENT_CSV: 'measurement_csv'
});
// 親スレッドからWorkerに通知するメッセージ種別
export const WORKER_TASK = Object.freeze({
  START: 'start',
  STOP: 'stop'
});
// Workerから親スレッドに返されるメッセージ種別
export const WORKER_EVENT = Object.freeze({
  SUCCESS_CONNECTION: 'success_connection',
  BASE_TIME: 'baseTime',
  CHUNK_DATA: 'chunkData',
  CSV_DATA: 'csv_data',
  MEASUREMENT_INFO: 'measurementInfo',
  SUCCESS_END: 'success_end',
  START_ERROR: 'start_error',
  RUNTIME_ERROR: 'runtime_error',
});

/**
 * TSVエクスポート処理
 * @param {Array} data TSVに出力するオブジェクトの配列。先頭オブジェクトのキー名をTSVのヘッダー項目にする。
 * @param {String} fileName ファイル名
 */
export function tsvFileExport(data, fileName) {
  if (!data || data.length === 0) {
    return;
  }

  const tsvData = convertToTSV(data);
  const blob = new Blob([tsvData], { type: TSV_CONTENT_TYPE });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

/**
 * TSVファイル変換処理
 */
function convertToTSV(data) {
  const tsvArray = [];
  const header = Object.keys(data[0]).map(key => `${key}`).join('\t');
  tsvArray.push(header);
  data.forEach(item => {
    const row = Object.values(item).map(value => `${value}`.replace('\n', '\\n').replace('\t', '\\t')).join('\t');
    tsvArray.push(row);
  });
  return tsvArray.join('\n');
}

/**
 * 物理変換された値から表示する値を取得する
 * @param {object} instance 画面コンポーネントのインスタンス（通常は this）を指定する
 * @param {string} did 選択項目のDID情報
 * @param {string} value 変換された計測値
 * @param {array} conversionInfo 変換情報リスト
 * @param {String|null} region リージョン文字列
 * @param {String} specifiedSystemId 任意のECUの翻訳キーを取得したい場合のみ指定
 * @returns 翻訳された計測値
 */
export function getTranslateValue(instance, did, value, conversionInfo, region, specifiedSystemId = null) {
  const cache = new Cache();
  const cachedValue = cache.get(did, value);
  // 0や空文字の場合はキャッシュを利用するため、nullやundefinedの時のみ翻訳情報を取得する
  if (cachedValue !== undefined && cachedValue !== null) {
    return cachedValue;
  }
  // "DID情報_変換結果"（翻訳用jsonを検索する文字列）
  const didValKey = did + "_" + value;

  // diagのjson参照用キー
  const findFFDi18nKey = individualDiagnose.getFFDi18nKeyWithRegion(instance, region, specifiedSystemId);
  const didConversionKey = `${findFFDi18nKey}.`;
  let didValDisp = "";

  // diagに存在する場合
  if (instance.$te(didConversionKey + didValKey)) {
    // diagから翻訳結果を取得
    didValDisp = instance.$t(didConversionKey + didValKey);
    // diagに存在しない場合
  } else {
    // decExStrの場合、翻訳結果が無ければ空を返す
    if (conversionInfo.data_type === "decExStr") {
      cache.set(did, value, "");
      return "";
    }
    const defaultKey = did + "_default";
    if (instance.$te(didConversionKey + defaultKey)) {
      didValDisp = instance.$t(didConversionKey + defaultKey, [value]);
    } else {
      // それ以外の場合、生値を返却
      cache.set(did, value, value);
      return value;
    }
  }

  // diagから翻訳結果を返却
  cache.set(did, value, didValDisp);
  return didValDisp;
}

/**
 * 翻訳対象の種別
 */
export const CACHE_CATEGORY = {
  UNIT: 'unit'
};

/**
 * 翻訳結果をキャッシュする
 * $tや$teを1秒に数千回と呼び出すと負荷が高いため、$tの結果を保持しておく
 */
export class Cache {
  translation = {};

  constructor() {
    if (Cache.instance) {
      return Cache.instance;
    }
    Cache.instance = this;
  }

  /**
   * 本クラスの後処理
   */
  destroy() {
    this.translation = {};
    Cache.instance = null;
  }

  /**
   * キャッシュから翻訳情報を取得する
   * @param {string | CACHE_CATEGORY} category DID(信号線項目のID)や翻訳対象の種別
   * @param {string} key 翻訳時のキー
   * @returns {string | undefined} キャッシュされた翻訳情報。キャッシュが無ければundefined
   */
  get(category, key) {
    if (!this.translation[category]) {
      this.translation[category] = {};
    }
    return this.translation[category][key];
  }

  /**
   * キャッシュに翻訳結果を設定する
   * @param {string | CACHE_CATEGORY} category DID(信号線項目のID)や翻訳対象の種別
   * @param {string} key 翻訳時のキー
   * @param {string} value 設定する翻訳結果
   */
  set(category, key, value) {
    this.translation[category][key] = value;
  }
}

export default {
  VALUE_TRANSLATE_TYPE,
  VALUE_CONVERT_DECIMAL_TYPE,
  CACHE_CATEGORY,
  tsvFileExport,
  getTranslateValue,
  Cache
};