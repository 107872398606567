<div class="container-fluid p-0" style="height: 100%">
  <div class="row no-gutters info-background">
    <div v-bind:class="[{ 'col-3': isTreeViewOpen }, { 'width-40': !isTreeViewOpen }]">
      <div>
        <div v-show="isTreeViewOpen" class="tree-seperator float-left" style="width: 90%;">
          <div v-if="!isTechnicalHq && isDisplayReproTreeView && isReproVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.header_vehicle_system_tree_repro') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="reproTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickReproTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>
          <div v-if="isDisplayTreeView && isAllDtcVisible && hasAllDtcSystems">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_all_dtc') }}</span>
            </div>
            <div>
              <system-tree ref="allDtcTreeView" v-bind:tree-data="allDtcTreeData" v-bind:showHeadElements="true"
                isAutoHeight @node-Selected="clickAllDtcTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>
          <div v-if="isDisplayTreeView && isIndividualVisible && hasIndividualMenus">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('individual.label_menu_individual_diagnosis') }}</span>
            </div>
            <div>
              <system-tree v-if="diagTreeData" ref="treeView" v-bind:tree-data="diagTreeData"
                v-bind:showHeadElements="true" isAutoHeight @node-Selected="clickDiagTreeNode"
                @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayImbTreeView && isImbVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left"> {{ $t('imb.common_immobilizer') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="immobilizerTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickImmobilizerTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayMacTreeView && isMackeyVisible">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('mac.label_mac_key') }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="macKeyTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickMacKeyTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <!-- OBDシステム 機能抑制 -->
          <!-- <div>
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ `${$t('fixed_resource.obd_menu_alldtc')}${$t('fixed_resource.obd_menu')}`
                }}</span>
            </div>
            <div>
              <system-tree ref="obdAllDtcTreeView" v-bind:tree-data="obdAllDtcTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickObdAllDtcNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div>
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ `${$t('fixed_resource.obd_menu_individual')}${$t('fixed_resource.obd_menu')}`
                }}</span>
            </div>
            <div>
              <system-tree ref="obdIndividualTreeView" v-bind:tree-data="obdIndividualTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickObdIndividualNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div> -->

          <div v-if="isDisplaySpecialFeature && isSpecialFeatureSupport">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_special_feature') }}</span>
            </div>
            <div>
              <system-tree v-if="specialFeatureTreeData" ref="specialTreeView" v-bind:tree-data="specialFeatureTreeData"
                v-bind:showHeadElements="true" isAutoHeight @node-Selected="clickSpecialFeatureTreeNode"
                @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

          <div v-if="isDisplayRealWorldDataTreeView && isDisplayRealWorldData">
            <div class="tree-header-div">
              <span class="tree-header-label float-left">{{ $t('message.label_function_menu_vehicle_information')
                }}</span>
            </div>
            <div>
              <system-tree v-bind:tree-data="realWorldDataTreeData" v-bind:showHeadElements="true" isAutoHeight
                @node-Selected="clickRealWorldDataTreeNode" @expanded-item="expandTree">
              </system-tree>
            </div>
          </div>

        </div>
        <div v-if="isTreeViewShow">
          <div class="div-arrow-radius" @click="switchTreeDisplay()">
            <svg v-if="isTreeViewOpen" viewBox="0 0 24 24" class="arrow-icon">
              <path d="M14 7l-5 5 5 5V7z" class="icon-color" />
              <path d="M24 0v24H0V0h24z" fill="none" />
            </svg>
            <svg v-else viewBox="0 0 24 24" class="arrow-icon">
              <path d="M10 17l5-5-5-5v10z" class="icon-color" />
              <path d="M0 24V0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-bind:class="[{ 'col-9': isTreeViewOpen }, { 'col': !isTreeViewOpen }]" style="width: 90%;">
      <div class="col-12 row" :id="idScrollTabBlock">
        <div class="col-auto text-left mt-3">
          <label class="system-name-style" v-if="isShowSelectedSystem">{{ selectedSystem }}</label>
        </div>
        <div class="col text-right mt-3">
          <svg-icon iconKey="carIcon"></svg-icon>
          <label v-show="specMode === 'abroad'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_vin') }} {{ vin }}</label>
          <label v-show="specMode === 'domestic'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_cn') }} {{ vin }}</label>
          <label v-show="specMode === 'abroad'" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_my')
            }} {{ spec }}</label>
          <label v-show="specMode === 'domestic'" class="mr-2 vehicle-info-style">{{
            $t('message.label_vehicle_system_cl') }} {{ spec }}</label>
          <label class="vehicle-info-style" :class="{ 'mr-2': specMode === 'domestic' }">{{
            $t('message.label_vehicle_system_model') }} {{ modelDisplayName }}</label>
          <label v-show="specMode === 'domestic'" class="vehicle-info-style">{{
            $t('message.label_vehicle_system_applied_model') }} {{ appliedModel }}</label>
        </div>
      </div>
      <router-view @updateReproTreeView="updateReproTreeView" @updateDiagTreeView="updateDiagTreeView"
        @updateMacTreeView="updateMacTreeView" @updateImmobilizerTreeView="updateImmobilizerTreeView"
        @activateInquiredNode="activateInquiredNode" @setIsSystemHeaderDisplay="setIsSystemHeaderDisplay"
        @updateSpecialFeatureTreeView="updateSpecialFeatureTreeView"
        @updateRealWorldDataTreeView="updateRealWorldDataTreeView" v-slot="{ Component }">
        <component ref="informationTabs" :is="Component" />
      </router-view>
    </div>
  </div>

  <!-- modal dialog  -->
  <div>
    <!-- 関連リプロ対象システムが存在している際に表示されるダイアログ -->
    <b-modal v-model="showModalRelation" id="modal-relation-repro" size="lg" header-bg-variant="info"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc>
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t('message.common_title_infomation') }}
        </div>
      </template>

      <template v-slot:default>
        <div class="text-left w-100 col-12 mt-3 pl-3" style="font-weight: bold;">
          <div v-html="relationReproProcessMsg"></div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="primary" @click="showModalRelation=false" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>