<div class="container-fluid ml-n2">
  <div>
    <div class="list-detail-top mt-2">
      <b-button class="ml-2 mt-2" variant="primary" size="sm" v-on:click="openNoteModal" :disabled="!projectId">
        {{ $t('message.button_vehicle_dtc_note_modal') }}
      </b-button>
      <b-button @click="clickAgainDataSet()" size="sm" class="mt-2" variant="primary">
        {{ $t('message.button_vehicle_dtc_gain_again') }}
      </b-button>
    </div>
  </div>

  <cstm-table useSort initSortDir="asc" hasTabs currentSortkey="mid" useStripeStyle
    :items="bodyItems" :columns="systemTableColumns" class="mt-2 ml-2" type="simple">
  </cstm-table>
  
  <!-- 実行不可画面 -->
  <b-modal id="modal-error" v-if="modalError" v-model="modalError" size="md" header-bg-variant="danger" header-text-variant="light"
    no-stacking no-close-on-backdrop no-close-on-esc centered>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_error') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid" style="min-height: 140px;">
        <div class="w-100">
          <label class="box-margin-right modal-error-message">{{ 
            $t('fixed_resource.obd_function_common_message_result_ng_2') }}</label>
        </div>
      </div>
    </template>

    <template v-slot:footer="{ ok }">
      <div class="w-100 text-center ">
        <b-button size="sm" variant="primary" @click="closeWizard()" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- メモ画面 -->
  <b-modal id="modal-memo" v-if="isMemoOpen" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>
    <memo-Page 
      ref="memo"
      :projectId="projectId"
      :measureId="measureId"
      functionNameKey="message.label_function_onboard_monitor_test"
      @closeNoteModal="closeNoteModal"
      :modalLoading="isMemoDialogLoading" 
      @update-loading="isMemoDialogLoading = $event" 
      />
    <template v-slot:footer="{ close, ok }">
      <button type="button" :disabled="isMemoDialogLoading" v-on:click="memoClear()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill">{{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="outline-primary" @click="closeNoteModal" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="primary" @click="memoSave()" class="ml-2 mr-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>

</div>