import config from '../config';
import utils from './utils';

// コード独自ソート
export const sortFuncCode = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let pCodeList = [];
    let cCodeList = [];
    let bCodeList = [];
    let uCodeList = [];
    const codeHyphenList = [];
    param.data.forEach(element => {
        if (element.dtc.indexOf('P') === 0) {
            pCodeList.push(element);
        }
        if (element.dtc.indexOf('C') === 0) {
            cCodeList.push(element);
        }
        if (element.dtc.indexOf('B') === 0) {
            bCodeList.push(element);
        }
        if (element.dtc.indexOf('U') === 0) {
            uCodeList.push(element);
        }
        if (element.description.indexOf('message.common_hyphen') > -1) {
            codeHyphenList.push(element);
        }
    });
    pCodeList = utils.sortItems(pCodeList, 'dtc', param.sortDir);
    cCodeList = utils.sortItems(cCodeList, 'dtc', param.sortDir);
    bCodeList = utils.sortItems(bCodeList, 'dtc', param.sortDir);
    uCodeList = utils.sortItems(uCodeList, 'dtc', param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(pCodeList);
        resultItems = resultItems.concat(cCodeList);
        resultItems = resultItems.concat(bCodeList);
        resultItems = resultItems.concat(uCodeList);
        resultItems = resultItems.concat(codeHyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(codeHyphenList);
        resultItems = resultItems.concat(uCodeList);
        resultItems = resultItems.concat(bCodeList);
        resultItems = resultItems.concat(cCodeList);
        resultItems = resultItems.concat(pCodeList);
        return resultItems;
    }
};

// FFD 独自ソート
export const sortFuncFfd = (param) => {
    if (!param.data) {
        return null;
    }
    const resultItems = utils.sortItems(param.data, 'buttonVisible', param.sortDir);
    return resultItems;
};

// 故障・診断独自ソート
export const sortFuncDescription = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    // 指定外コードリスト
    const unspecifiedList = [];
    // hyphenリスト
    const hyphenList = [];
    // DTC割り当て有りリスト
    let dtcDescriptionLists = [];
    param.data.forEach(element => {
        if (element.description.indexOf('message.status_vehicle_dtc_description_unspecified') > -1) {
            unspecifiedList.push(element);
        }
        if (element.description.indexOf('message.common_hyphen') > -1) {
            hyphenList.push(element);
        }
        // dtc、またはbmcの説明かどうかを判定
        if (element.description.indexOf('dtc.') > -1 || element.description.indexOf('bmc') > -1) {
            dtcDescriptionLists.push(element);
        }
    });
    dtcDescriptionLists = utils.sortItems(dtcDescriptionLists, 'description', param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = dtcDescriptionLists.concat(unspecifiedList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = hyphenList.concat(unspecifiedList);
        resultItems = resultItems.concat(dtcDescriptionLists);
        return resultItems;
    }
};

// IGON回数 独自ソート
export const sortIgon = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let igonList = [];
    const hyphenList = [];
    param.data.forEach(element => {
        if (element[param.sortKey] !== '-') {
            igonList.push(element);
        }
        else {
            hyphenList.push(element);
        }
    });
    igonList = utils.sortItems(igonList, param.sortKey, param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(igonList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(hyphenList);
        resultItems = resultItems.concat(igonList);
        return resultItems;
    }
};

// 汎用独自ソート
const sortFuncItems = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let itemList = [];
    const hyphenList = [];

    param.data.forEach(element => {
        if (element[param.sortKey] !== '-') {
            itemList.push(element);
        }
        else {
            hyphenList.push(element);
        }
    });
    itemList = utils.sortItems(itemList, param.sortKey, param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(itemList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(hyphenList);
        resultItems = resultItems.concat(itemList);
        return resultItems;
    }
};

// 日付独自ソート
export const sortDate = (param) => {
    if (!param.data) {
        return null;
    }
    // 結果リスト
    let resultItems = [];
    let dateList = [];
    const hyphenList = [];
    const invalidList = [];
    param.data.forEach(element => {
        if (element[param.sortKey] === '-') {
            hyphenList.push(element);
        } else if (!Date.parse(element[param.sortKey])) {
            invalidList.push(element);
        } else {
            dateList.push(element);
        }
    });
    dateList = utils.sortItems(dateList, param.sortKey, param.sortDir);

    // 昇順の場合
    if (param.sortDir === config.ASC) {
        resultItems = resultItems.concat(dateList);
        resultItems = resultItems.concat(invalidList);
        resultItems = resultItems.concat(hyphenList);
        return resultItems;
    }
    // 降順の場合
    else {
        resultItems = resultItems.concat(hyphenList);
        resultItems = resultItems.concat(invalidList);
        resultItems = resultItems.concat(dateList);
        return resultItems;
    }
};

// 診断系のAPIは複数画面から使用されているため
// 現在の画面で使用されるAPIが診断/データ再生/個別診断のうちどの機能なのか判別する
export const operationFrom = (currentRoute) => {
    let screen = '';
    switch (currentRoute) {
        case 'dataRecollection':
            screen = config.EXECUE_FUNCTION_TYPE_DATAPLAYBACK;
            break;
        case 'diagProjectDetails':
            screen = config.EXECUE_FUNCTION_TYPE_DATAPLAYBACK;
            break;
        case 'home':
            screen = config.EXECUE_FUNCTION_TYPE_DIAG;
            break;
        case 'dtcTable':
            screen = config.EXECUE_FUNCTION_TYPE_DIAG;
            break;
        case 'blank':
            screen = config.EXECUE_FUNCTION_TYPE_DIAG;
            break;
        // 特殊機能ダイアグコードは機能プランの特殊機能対応時に特殊機能側に移動する
        case 'individualDiagCode':
        case 'individualWorkSupport':
        case 'individualCancelCode':
        case 'individualCustomize':
        case 'individualVehicleControlHistory':
        case 'spDiagCode':
        case 'spWorkSupport':
        case 'spCancelCode':
            screen = config.EXECUE_FUNCTION_TYPE_INDIVIDUAL_DIAG;
            break;
        case 'generalObdGetVehicleInfo':
        case 'generalObdOnboardMonitorTest':
            screen = 'obdSystem';
            break;
    }
    return screen;
};

export default {
    sortFuncCode,
    sortFuncFfd,
    sortFuncDescription,
    sortIgon,
    sortFuncItems,
    sortDate,
    operationFrom
};