const moment = require('moment');
import util from './utils';

/**
 * FD画面のCSV出力を行う関数
 * @param self 呼び出し元のthis
 * @returns 成功時のみレスポンスを返す（失敗は null を返す）
 */
export const detailFdToCsv = async (self) => {
  let header = '';
  const vehicle = self.vehicleInfo.vehicle;
  // 1行目～8行目までの出力内容は詳細FD表以外のデータ
  // 1行目(データ名) + 改行コード
  header += self.$t('message.label_measured_data_details_data_name') + ',' + self.dataName + '\n';
  // 2行目(メモ)
  header += self.$t('message.label_measured_data_details_notes') + ',' + self.memo + '\n';
  // 3行目(VIN)
  header += self.$t('message.label_vehicle_system_vin').replace(':', '') + ',' + vehicle.vin.vin + '\n';
  // 4行目(車台番号)空固定
  header += self.$t('message.label_chassis_number') + '\n';
  // 5行目(登録番号)
  if (self.hideRegistrationNumber) {
    self.registrationNumber = ('{0} {1} {2} {3}').format(
      util.isNullOrDefault(vehicle.vin.area_name, ''),
      util.isNullOrDefault(vehicle.vin.category, ''),
      util.isNullOrDefault(vehicle.vin.hiragana, ''),
      util.isNullOrDefault(vehicle.vin.individual_number, '')
    );
  }
  const registrationNumber = util.isNullOrDefault(self.registrationNumber, '');
  header += self.$t('message.label_registration_number') + ',' + registrationNumber + '\n';
  // 6行目(車名)
  header += self.$t('message.label_measured_data_filter_vehicle_name') + ',' + vehicle.spec.model + '\n';
  // 7行目(車型)
  const vehicleTypeName = vehicle.spec.model_year + vehicle.spec.model;
  header += self.$t(self.i18nWsKey + '.label_Vehicle_type') + ',' + vehicleTypeName + '\n';
  // 8行目(詳細FDステータス)
  header += self.$t('individual.header_vehicle_system_table_details_fd') + ':,' + self.statusValue + '\n';
  // 9行目以降は詳細FD画面に表示されている内容
  // 項目名作成
  let body = self.$t('individual.label_counter') + ',';
  body += self.displayFdInfo.map(obj => {
    return self.$te(`${self.i18nWsDidKey}.${obj.did}_1_all`) ? self.$t(`${self.i18nWsDidKey}.${obj.did}_1_all`)
    : self.$te(`${self.i18nSpwsDidKey}.${obj.did}_1_all`) ? self.$t(`${self.i18nSpwsDidKey}.${obj.did}_1_all`)
    : "";
  }).join(',') + '\n';
  // 単位作成
  body += self.$t('individual.header_vehicle_system_table_cnt') + ',';
  body += self.displayFdInfo.map(obj => {
    return obj.unit;
  }).join(',') + '\n';
  // 表示データ作成
  const itemList = [];
  // 回数作成
  const counterList = [];
  for (let i = 0; i < self.tableFdColumns.length; i++) {
    if (self.tableFdColumns[i].name.startsWith('count_')) {
      counterList.push(Number(self.tableFdColumns[i].name.substring(6)) + 1);
    }
  }
  itemList.push(counterList);

  for (let i = 0; i < self.displayFdInfo.length; i++) {
    const displayFdInfoList = new Array();
    for (let j = 0; j < self.preDetailFdData[0].detail_fd_data_list[0].read_data.length; j++) {
      displayFdInfoList.push(self.displayFdInfo[i]['count_' + j]);
    }
    itemList.push(displayFdInfoList);
  }
  // 表示データの行列入れ替え
  body = transpose(body, itemList);

  // ダミー処理（ファイルエクスポートが高速で終了するので、操作者に通信の発生を認識させる）
  await util.sleep(1000);

  const data = header + body;
  const fileName = moment().format('YYYYMMDDhhmmsss');
  const bomFormatData = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
  const blob = new Blob([bomFormatData, data], { type: 'text/csv;charset=utf-8' });
  const link = window.document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = ('{0}.csv').format(fileName); // 出力するファイルの名前
  link.style.display = 'none';
  link.click();
  link.remove();
  return null;
};

/**
 * 配列の行列入れ替え
 * @param {String} body csv出力するボディ
 * @param {Array} arr 表示データ配列
 * @returns 行列入れ替えを行った配列
 */
const transpose = (body, arr) => {
  let addBody = body;
  const result = [];
  for (let i = 0; i < arr[0].length; i++) {
    result[i] = [];
    for (let j = 0; j < arr.length; j++) {
      result[i][j] = arr[j][i];
    }
    result[i].push('\n');
    addBody = addBody + result[i];
  }
  return addBody;
};

export default {
  detailFdToCsv
};