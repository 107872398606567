/**
 * 診断システムのユーティリティ関数群をまとめたクラス
 */
class DiagSystemUtils {

  /**
   * システム名称がエンジンに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isEngine(systemName) {
    return ['EGI_OLD', 'EGI', 'PU_EGI', 'EGI_25ePF'].includes(systemName);
  }

  /**
   * システム名称がトランスミッションに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isTransmission(systemName) {
    return ['TM', 'TM_OLD', 'TM_25ePF'].includes(systemName);
  }

  /**
   * システム名称がアイサイトに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isEyesight(systemName) {
    return ['ES', 'ES_25ePF'].includes(systemName);
  }

  /**
   * システム名称がブレーキコントロールに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isBrakeControl(systemName) {
    return ['BC', 'BC_25ePF'].includes(systemName);
  }

  /**
   * システム名称がボディコントロールに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isBodyControl(systemName) {
    return ['BIU', 'BIU_25ePF'].includes(systemName);
  }

  /**
   * システム名称がエアバッグに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isAirbag(systemName) {
    return ['ABG', 'ABG_25ePF'].includes(systemName);
  }

  /**
   * システム名称がセントラルECUに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isCentralEcu(systemName) {
    return ['C-ECU'].includes(systemName);
  }

  /**
   * システム名称が電制ダンパーに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
  static isDamper(systemName) {
    return ['DAM', 'DAM_25ePF'].includes(systemName);
  }

  /**
   * システム名称がキーレスエントリーに該当するか判定する
   * @param {string} systemName システム名称
   * @returns {boolean} true(該当)/false(非該当)
   */
    static isKeylessEntry(systemName) {
      return ['KLW'].includes(systemName);
    }
}

module.exports = DiagSystemUtils;
